<template>
  <div class="purchase-plan">
    <div class="header-search">
      <span class="search-item">
        <span class="search-name">采购计划名称:</span>
        <comInput class="input-width" v-model="purchaseNameData.value" :data='purchaseNameData'></comInput>
      </span>
      <span class="search-item">
        <span class="search-name">状态:</span>
        <comSelect class="input-width select-status" v-model="statusData.value" :data='statusData'></comSelect>
      </span>
      <span class="search-item">
      <span class="search-name">发布日期:</span>
        <el-date-picker class="date"
          v-model="createTimeData.value"
          type="daterange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd">
      </el-date-picker>
      </span>
      <span class="operate-btn">
        <fontButton @click="search">查询</fontButton>
        <fontButton @click="reset">重置</fontButton>
        <fontButton @click="goAdd">新增</fontButton>
      </span>
    </div>
    <div class="statistic">
      <statisticBar :statisticData="statisticData" />
    </div>
    <div class="table-main">
      <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
        <template slot="operate" slot-scope="data">
          <span @click="goDetail(data.data.row)" class="table-btn">查看</span>
          <span v-if="data.data.row.status ==2" @click="goResult(data.data.row)" class="table-btn">查看结果</span>
          <span v-if="data.data.row.status==-1" @click="goEdit(data.data.row)" class="table-btn">编辑</span>
          <template v-if="data.data.row.status !==2">
            <span v-if="data.data.row.status === -1" @click="goSetStatus(data.data.row,1)" class="table-btn">启用</span>
            <span v-if="data.data.row.status === 1" @click="goSetStatus(data.data.row,-1)" class="table-btn red">禁用</span>
          </template>
          <span v-if="data.data.row.status === -1" @click="goDelete(data.data.row)" class="table-btn">删除</span>
        </template>
      </ContentTable>
    </div>
    <infoDetail :type="detailType" width="850px" :resultData="resultData"  :detailData="detailData" :show.sync="detailShow" :title="detailTitle"></infoDetail>
    <newAndEdit v-if="formShow" :planHeight="true" :formType="formType" :formData="formData" :show.sync="formShow" :title="formTitle"  
       @checkChange="checkChange" @select_change="formSelectChange"  @saveData="newAndEditSave" @dataChange="comchange">
    </newAndEdit>
  </div>
</template>
<script>
import {tableData_plan} from "../../common/data"
import basePage from "../../common/basePage.vue"
import {ajax_get_planList, ajax_post_planAdd, ajax_post_planEdit, ajax_post_planStatus,
    ajax_get_enumsMaterial, ajax_get_enumsModel, ajax_get_planresult} from "../../../../api/index"
export default {
  name: 'purchasePlan',
  mixins: [basePage],
  data () {
    return {
      purchaseNameData:{
        placeholder: "请输入采购计划名称",
        value: ""
      },
      statusData:{
        placeholder: "请选择状态",
        value: "",
        options:[
          {label:"全部", value:""},
          {label:"未启用", value:-1},
          {label:"启用", value:1},
          {label:"交易完成", value:2},
        ]
      },
      createTimeData:{
        value:""
      },
      statisticData:[
        {name:"全部计划", value:"123", id:"allCount"},
        {name:"未启用", value:"2", id:"auditCount"},
        {name:"启用", value:"2", id:"enableCount"},
        {name:"已完成", value:"2", id:"completedCount"},
      ],
      editId:"",
      materialOption:[],
      materialOptionObj:{},
      modelOption:[],
      modelOptionObj:{},
      resultData:{},
      detailType:"detail",
      detailTitle:"计划详情",
    }
  },
  created(){
    this.pageInit();
  },
  methods:{
    pageInit(){
      this.ajaxListMethods = ajax_get_planList;
      this.tableData = this.$deepClone(tableData_plan)
      this.generateDetailArr();
      this.generateFormArr("plan")
      this.ajax_list_get(true)
      this.getMaterial_list();
      this.ajax_get_statistic()
    },
    async ajax_get_statistic(){
      let res = await this.ajax_get_statisticData(2)
      console.log(res,"statistc")
      this.statisticData.forEach(v=>{
        v.value = res[v.id]
      })
    },
    search(){
      this.ajaxGetData.purchaseName = this.purchaseNameData.value;
      this.ajaxGetData.status = this.statusData.value
      if(this.createTimeData.value && this.createTimeData.value.length){
        this.ajaxGetData.startTime = this.createTimeData.value[0] + " 00:00:00";
        this.ajaxGetData.endTime = this.createTimeData.value[1] + " 23:59:59";
      }else{
        this.ajaxGetData.startTime ="";
        this.ajaxGetData.endTime = '';
      }
      this.ajax_list_get(true)
    },
    handerTableData(list){
      let resList = [];
      list.forEach((element,index)=>{
        element.statusStr = (this.statusData.options.find(v=>v.value == element.status) || {}).label || element.status
        element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
        resList.push(element)
      })
      return resList;
    },
    reset(){
      this.purchaseNameData.value = "";
      this.statusData.value = "";
      this.createTimeData.value = "";
    },
    goAdd(){
      this.editId = "";
      this.formInit(true)
      this.handlerFormOption();
    },
    goEdit(data){
      this.formInit(false)
      this.tableToForm(data)
      this.handlerFormData(data);
      this.handlerFormOption(data);
      this.editId = data.purchaseOrderNumber
    },
    handlerFormData(data){
      this.formData.forEach(element=>{
        if(element.id == "purchaseSubmitStartTime"){
          element.value = [data.purchaseSubmitStartTime, data.purchaseSubmitEndTime]
        }
        if(element.id == "agencyOfferStartTime"){
          element.value = [data.agencyOfferStartTime, data.agencyOfferEndTime]
        }
        if(element.id == "material"){
          console.log(data.material,"data.material")
          element.value = Number(this.materialOptionObj[data.material])
          this.getModel_list(element.value,true, data)
        }
        if(element.id == "orderModelId"){
          element.value = data.orderModelId ? data.orderModelId.split(",") : ""
        }
      })
    },
    handlerFormOption(data){
      this.formData[1].options = this.$deepClone(this.materialOption);
      this.formData.forEach(v=>{
        if(v.id == "orderModelId"){
          v.expand = {
            multiple: true,
            right_slot: "checkAll", // // 型号全选配置
          }
        }
        if(v.id == "purchaseSubmitStartTime" || v.id == "agencyOfferStartTime" ){
          v.expand = {
            type: "datetimerange"
          }
        }
        if( v.id == "resultPublishTime"){
          v.expand = {
            type: "datetime"
          }
        }
        // 成交权重配置
        if( v.id == "calculateType"){
          if(!data) v.value = 1
          v.options = [
            {name:"权重分配", id:1},
            {name:"平均分配", id:2},
          ]
        }
        if(!data && v.id == "weightNum"){
          v.value = 30
        }
        if(!data && v.id == "weightPrice"){
          v.value = 30
        }
        if(!data && v.id == "weighScore"){
          v.value = 40
        }
      })
    },
    get_result(data){
      return new Promise((resolve, reject)=>{
        ajax_get_planresult({id:data.id,purchaseOrderNumber:data.purchaseOrderNumber}).then(res=>{
          if(res.code == 200){
            resolve(res.result)
          }else{
            reject(res)
          }
        }).catch(err=>{
          console.log("ajax_err,", err)
          reject(err)
        })
      })
    },
    async goResult(data){
      this.detailTitle = "成交结果"
      let result = await this.get_result(data)
      console.log(result,"result")
      result.material = data.material
      result.orderModel = data.orderModel
      this.resultData = result;
      this.detailType = "result"
      this.detailShow = true;
    },
    goDetail(data){
      this.detailTitle = "计划详情"
      this.detailType = "detail"
      this.tableToDetail(data)
      this.detailData = this.tableToDetailSelf()
      this.handlerDetail(data)
      this.detailShow = true;
    },
    tableToDetailSelf(){
      let arr = []
      this.detailData.forEach(v=>{
        if(v.id !== "orderModelId"){
          arr.push(v)
        }
      })
      return arr
    },
    handlerDetail(data){
      this.detailData.forEach(v=>{
        if(v.id == "purchaseSubmitStartTime"){
          v.value = data.purchaseSubmitStartTime + " 至 " + data.purchaseSubmitEndTime
        }
        if(v.id == "agencyOfferStartTime"){
          v.value = data.agencyOfferStartTime + " 至 " + data.agencyOfferEndTime
        }
        if(v.id == "calculateType"){
          v.value = data.calculateType == 1 ? "权重分配" : "平均分配"
        }
      })
    },
    goDelete(data){
      this.$Confirm({
        title:'警告',
        info:'请确认是否删除此信息',
      }).then(()=>{
        const params = {
          id: data.id,
          status: -2,
        }
        ajax_post_planStatus(params).then(res=>{
          if(res.code == 200){
            this.$message.success("操作成功")
            this.ajax_list_get()
          }
        }).catch(err=>{
          console.log("ajax_err:", err)
        })
      })
    },
    newAndEditSave(){
      let saveMethods = this.formType==="add" ? ajax_post_planAdd : ajax_post_planEdit
      const params = this.formToSubmit()
      let weightSum = Number(params.weighScore) + Number(params.weightNum) + Number(params.weightPrice)
      if(weightSum !== 100){
        this.$message.error("权重填写错误")
        return
      }
      if(this.formType==="edit") params.purchaseOrderNumber = this.editId;
      saveMethods(params).then(res=>{
        if(res.code == 200){
          this.$message.success("操作成功")
          this.formShow = false;
          this.ajax_list_get()
        }
      }).catch(err=>{
        console.log("ajax_err:",err)
      })
    },
    formToSubmit(){
      let obj = {}
      this.formData.forEach(v=>{
        if(v.id === "agencyOfferStartTime"){
          const agencyOfferStartTimeArr = v.value
          obj.agencyOfferStartTime = agencyOfferStartTimeArr[0]
          obj.agencyOfferEndTime = agencyOfferStartTimeArr[1]
        }else if(v.id === "purchaseSubmitStartTime"){
          const purchaseSubmitStartTimeArr = v.value
          obj.purchaseSubmitStartTime = purchaseSubmitStartTimeArr[0]
          obj.purchaseSubmitEndTime = purchaseSubmitStartTimeArr[1]
        }else if(v.id === "material"){
          obj.materialId = v.value
          obj.material = this.materialOptionObj[v.value]
        }else if(v.id === "orderModelId"){
          let str = ""
          for(let i=0;i<v.value.length;i++){
            let value = v.value[i]
            str+=this.modelOptionObj[value]+","
          }
          let arr = str.split(",")
          console.log(str)
          arr.splice(arr.length-1,1)
          let res = arr.join()
          obj.orderModel = res
          obj.orderModelId = v.value.join(",");
        }else{
          obj[v.id] = v.value
        }
      })
      return obj
    },
    goSetStatus(data, status){
      const params = {
        id: data.id,
        status: status
      }
      ajax_post_planStatus(params).then(res=>{
        this.$message.success("操作成功")
        this.ajax_list_get()
      }).catch(err=>{
        console.log("ajax_err:",err)
      })
    },
    getMaterial_list(){
      this.materialOption = []
      this.materialOptionObj = {}
      ajax_get_enumsMaterial().then(res=>{
        if(res.code == 200){
          const result = res.result
          result.forEach(v=>{
            this.materialOptionObj[v.id] = v.materialName;
            this.materialOptionObj[v.materialName] = v.id;
            this.materialOption.push({
              label:v.materialName,
              value:v.id
            })
          })
        }
      })
    },
    formSelectChange(data){
      console.log(data,"select-change")
      if(data.data.id == "material"){
        this.formData[2].value = ""
        this.getModel_list(data.data.value)
      }
    },
    comchange(data,value){
    },
    checkChange(val){
      console.log(val)
      if(val){
        let options = this.formData[2].options
        let arr = []
        options.forEach(v=>{
          arr.push(v.value)
        })
        this.formData[2].value = arr
      }else{
        this.formData[2].value = []
      }
    },
    getModel_list(id, setFormFlag = false, data){
      this.modelOption = []
      this.modelOptionObj = {}
      ajax_get_enumsModel({materialId:id}).then(res=>{
        if(res.code == 200){
          const result = res.result
          result.forEach(v=>{
            this.modelOptionObj[v.id] = v.modelName
            this.modelOptionObj[v.modelName] = v.id
            this.modelOption.push({
              label:v.modelName,
              value:v.id + ""
            })
          })
          this.formData[2].options = this.$deepClone(this.modelOption)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.purchase-plan {
  height: 100%;
  width: 100%;
  font-size: 14px;
  .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    .search-item{
      margin-right: 15px;
      .date{
         width: 210px;
      }
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
      .select-status{
        width: 70px;
      }
    }
    .operate-btn{

    }
  }
  .table-main{

  }
}
</style>

